.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #dd0223;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #c4021f;
}
.button:active {
  background-color: #910117;
}
div.navi {
  position: relative;
  z-index: 1;
  float: right;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
  color: #787878;
  font-weight: 400;
}
@media (min-width: 1025px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  margin-top: 15px;
}
div.sub1 > .item {
  margin: 0 10px;
  position: relative;
}
div.sub1 > .item:not(.item-empty) > .cb-toggle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
div.sub1 > .item.init {
  margin-left: 0;
}
div.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  border-top: 2px solid transparent;
  padding-top: 8px;
  padding-bottom: 25px;
}
div.sub1 > .item > .menu.path {
  border-top-color: #dd0223;
}
div.sub2 {
  margin-left: -10000000px;
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), max-height 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.sub2 > .item {
  float: none;
  display: inline-block;
}
div.sub2 > .item > .menu {
  position: relative;
  display: inline-block;
  margin-top: 1px;
  padding: 12px 10px;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus,
div.sub2 > .item > .menu.path {
  background-color: #e6e6e6;
}
div.sub1 > .item.cb-toggle-target-active div.sub2 {
  max-height: 48px;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), max-height 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.second-level-navi {
  *zoom: 1;
  position: absolute;
  top: 109px;
  width: 100%;
}
.second-level-navi:before,
.second-level-navi:after {
  display: table;
  content: '';
}
.second-level-navi:after {
  clear: both;
}
.second-level-navi div.navi.sub2 {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #787878;
}
body.sub2 .second-level-navi div.sub2 {
  max-height: 48px;
  opacity: 1;
  transform: translateY(0);
}
.item.path div.navi.sub2 {
  display: none;
}
#services .meta.service_phone {
  display: none;
}
.navbar .desk {
  z-index: 10;
}
.navbar:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 109px;
  border-bottom: 1px solid #a8a8a7;
}
.cb-layout1 .downlink {
  position: absolute;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  display: block;
  width: 40px;
  height: 20px;
  background: url(/images/downlink.svg) no-repeat center / 100% 100%;
  cursor: pointer;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
}
.cb-layout2 .area.main {
  width: 100%;
}
.cb-layout2 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.main .cb-album .body,
.cb-layout2 .area.main .head,
.cb-layout2 .area.main .foot,
.cb-layout2 .area.main .part {
  margin-right: 1.44444444%;
  margin-left: 1.44444444%;
  width: 97.11111111%;
}
.cb-layout2 .area.main .tiny {
  width: 47.11111111%;
}
.cb-layout2 .area.main > .slim {
  width: 50%;
}
.cb-layout2 .area.main > .slim .head,
.cb-layout2 .area.main > .slim .foot,
.cb-layout2 .area.main > .slim .part {
  margin-right: 2.88888889%;
  margin-left: 2.88888889%;
  width: 94.22222222%;
}
.cb-layout2 .area.main > .slim.cb-album .body {
  margin-right: 2.88888889%;
  margin-left: 2.88888889%;
  width: 94.22222222%;
}
.cb-layout2 .area.main > .slim .tiny {
  width: 44.22222222%;
}
.cb-layout1 .area.main .unit.slim {
  column-count: 3;
}
/*# sourceMappingURL=./screen-large.css.map */